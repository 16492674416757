.deviant-scrollbars>.os-scrollbar-vertical {
    height: 95% !important;
}

.deviant-scrollbars.os-host-scrollbar-horizontal-hidden>.os-scrollbar-vertical {
    top: 2.5% !important;
}

.react_table.os-host>.os-padding>.os-viewport>.os-content {
    height: auto !important;
}

.react_table>.os-scrollbar-vertical {
    padding-top: 50px !important;
}