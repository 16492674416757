@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(/src/assets/css/Steper.css);
@import url(/src/assets/css/Table.css);
@import url(/src/assets/css/ScrollBar.css);
@import url(/src/assets/css/DynamicTailwindCss.css);