.collapsed {
    visibility: collapse !important;
    height: 0 !important;
    max-height: 0 !important;
    line-height: 0 !important;
    overflow: hidden !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
}

