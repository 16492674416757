.steps .done:not(:first-child)::before {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    left: 0;
    @apply bg-succes;
}

.steps .done:not(:last-child)::after {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    right: 0px;
    @apply bg-succes;
}

.steps .in_progress:not(:first-child)::before {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    left: 0;
    @apply bg-warning;
}

.steps .in_progress:not(:last-child)::after {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    right: 0px;
    @apply bg-warning;
}

.steps .to_do:not(:first-child)::before {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    left: 0;
    @apply bg-grey;
}

.steps .to_do:not(:last-child)::after {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    right: 0px;
    @apply bg-grey;
}

.loading .to_do:not(:first-child)::before {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    left: 0;
    @apply bg-gray-200;
}

.loading .to_do:not(:last-child)::after {
    content: "";
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: -1;
    right: 0px;
    @apply bg-gray-200;
}

@media only screen and (max-width : 768px) {
    .steps .done:not(:first-child)::before {
        content: "";
        width: 5px;
        height: 50%;
        position: absolute;
        z-index: -1;
        left: unset;
        top: 0;
        @apply bg-succes;
    }

    .steps .done:not(:last-child)::after {
        content: "";
        width: 5px;
        height: 50%;
        position: absolute;
        z-index: -1;
        right: unset;
        bottom: 0px;
        @apply bg-succes;
    }

    .steps .in_progress:not(:first-child)::before {
        content: "";
        width: 5px;
        height: 50%;
        position: absolute;
        z-index: -1;
        left: unset;
        top: 0;
        @apply bg-warning;
    }

    .steps .in_progress:not(:last-child)::after {
        content: "";
        width: 5px;
        height: 50%;
        position: absolute;
        z-index: -1;
        right: unset;
        bottom: 0px;
        @apply bg-warning;
    }

    .steps .to_do:not(:first-child)::before {
        content: "";
        width: 5px;
        height: 50%;
        position: absolute;
        z-index: -1;
        left: unset;
        top: 0;
        @apply bg-grey;
    }

    .steps .to_do:not(:last-child)::after {
        content: "";
        width: 5px;
        height: 50%;
        position: absolute;
        z-index: -1;
        right: unset;
        bottom: 0px;
        @apply bg-grey;
    }
}